import '../assets/css/reset.css';
import '../assets/css/fonts.css';
import React from 'react';
import { CacheProvider } from '@emotion/core';
import { Auth0Provider } from '@auth0/auth0-react';
import { cache } from 'emotion';
import Router from 'next/router';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import CssBaseline from '@material-ui/core/CssBaseline';
import Head from 'next/head';
import Script from 'next/script';
import { useInitApp } from '../src/hooks/useInitializeApp';

import { Core } from '../src/integrated-components/_common/Core';
import { PageAuthCheck } from '../src/integrated-components/_common/PageAuthCheck';
import { PauseTheme } from '../src/components/PauseTheme';
import { PauseSnackbarProvider } from '../src/integrated-components/snackbar';
import { PauseQueryClientProvider } from '../src/api/query-client';
import { TrialExpiryNotification } from '../src/integrated-components/_common/TrialExpiryNotification';
import { PauseSubscriptionCheck } from '../src/integrated-components/_common/PauseSubscriptionCheck';

const PauseApp = ({ children }) => {
  useInitApp();
  return (
    <PageAuthCheck>
      <PauseSubscriptionCheck>{children}</PauseSubscriptionCheck>
    </PageAuthCheck>
  );
};

const onRedirectCallback = (appState) => {
  // Use Next.js's Router.replace method to replace the url
  Router.replace(appState && appState.returnTo ? appState.returnTo : '/');
};

const App = (props) => {
  const { Component, pageProps, err } = props;

  const skipAuth0RedirectURLs = [
    '/settings',
    '/preferences',
    '/preferences/slack',
    '/preferences/calendar',
    '/start',
    '/teams',
  ];
  return (
    <ThemeProvider theme={PauseTheme}>
      <EmotionThemeProvider theme={PauseTheme}>
        <StylesProvider injectFirst>
          <CssBaseline />

          <Auth0Provider
            domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ''}
            audience={process.env.NEXT_PUBLIC_AUDIENCE}
            clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ''}
            /* eslint-disable-next-line no-undef */
            skipRedirectCallback={
              typeof window !== 'undefined' && skipAuth0RedirectURLs.some((path) => window.location.href.includes(path))
            }
            redirectUri={typeof window !== 'undefined' ? window.location.origin : ''}
            onRedirectCallback={onRedirectCallback}
            scope={process.env.NEXT_PUBLIC_AUTH0_SCOPE}
            cacheLocation="localstorage"
            useRefreshTokens
          >
            {/* <AppLoadingProvider> */}
            <PauseQueryClientProvider>
              <PauseSnackbarProvider>
                <CacheProvider value={cache}>
                  <Core />
                  <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
                  </Head>
                  <PauseApp>
                    <>
                      <Component {...pageProps} err={err} />
                      {process.env.NEXT_PUBLIC_FEATURE_PAYMENTS && <TrialExpiryNotification />}
                    </>
                  </PauseApp>
                </CacheProvider>
              </PauseSnackbarProvider>
            </PauseQueryClientProvider>
            {/* </AppLoadingProvider> */}
          </Auth0Provider>
        </StylesProvider>
      </EmotionThemeProvider>
    </ThemeProvider>
  );
};

export default App;
