import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '../hooks/useAxios';
import { QueryNames } from '../types/QueryNames';
import {
  CheckpointDataAPIResponseFormatted,
  CheckpointOnboardingData,
  LeaveType,
  OnboardingStep,
} from '../types/onboarding';
import { useEnhancedRouter } from '../../router';
import { getRouteFromOnboardingStep } from '../../utils/onboarding2';

const { get, post } = api();

const getCheckpointData = (): Promise<CheckpointDataAPIResponseFormatted> =>
  get(
    {
      url: '/onboarding/checkpoint',
    },
    (d) => ({
      ...d,
      data: d?.data ? JSON.parse(d?.data) : null,
    }),
  );
const updateCheckpointDataAPI = (cpData: CheckpointOnboardingData): Promise<any> =>
  post({
    url: '/onboarding/checkpoint',
    data: {
      data: JSON.stringify(cpData || {}),
    },
  });
const finishOnboardingAPI = (cpData: CheckpointOnboardingData): Promise<any> => {
  const request = {
    creator: {
      name: cpData?.org?.orgCreatorName,
    },
    org: {
      name: cpData?.org?.orgName,
      cycleStartMonth: cpData?.org?.leaveCycleStartMonth,
      timezone: cpData?.org?.timezone,
    },
    leaveTypes: (cpData?.leaveTypes || []).map((lt: LeaveType) => {
      if (lt.allowance.isNonDeductible) {
        return { name: lt.name };
      }
      return {
        name: lt.name,
        allowance: {
          amount: lt.allowance.amount,
          type: lt.allowance.type,
        },
      };
    }),
  };
  return post({
    url: '/onboarding/newliftoff',
    data: request,
  });
  // return new Promise((res) => setTimeout(() => res(request), 2000));
};

export const initialCheckpointData: CheckpointOnboardingData = {
  step: OnboardingStep.OrgExistsCheck,
  org: null,
  leaveTypes: null,
};

export const useOnboardingData = ({
  enabled = true,
}: {
  enabled?: boolean;
} = {}) => {
  const { data: checkpointData, isLoading: loading } = useQuery<CheckpointDataAPIResponseFormatted>(
    QueryNames.CheckpointData,
    getCheckpointData,
    {
      enabled,
    },
  );
  return {
    checkpointData: checkpointData?.data || initialCheckpointData,
    email: checkpointData?.userEmail || '',
    loading,
    org: checkpointData?.domainDetails?.nameOfEarliestOrgWithThisDomain || null,
  };
};

export const useOnboardingMutations = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateCheckpointData } = useMutation<any, any, CheckpointOnboardingData>(
    updateCheckpointDataAPI,
  );
  const { mutateAsync: finishOnboarding } = useMutation<any, any, CheckpointOnboardingData>(finishOnboardingAPI, {
    // onSuccess: () => {
    //   queryClient.invalidateQueries('org');
    //   queryClient.invalidateQueries('me');
    // },
  });
  const router = useEnhancedRouter();
  const goToNextStep = (nextStep: OnboardingStep) => async (newCheckpointData: CheckpointOnboardingData) => {
    queryClient.setQueryData(QueryNames.CheckpointData, (oldData: any) => ({
      ...oldData,
      data: {
        ...oldData?.data,
        ...newCheckpointData,
        step: nextStep,
      },
    }));
    const route = getRouteFromOnboardingStep(nextStep);
    await updateCheckpointData({
      ...newCheckpointData,
      step: nextStep,
    });
    try {
      if (nextStep === OnboardingStep.Done) {
        await finishOnboarding(newCheckpointData);
        window.location.href = window.location.origin + route;
        return Promise.resolve();
      }

      await router.push(route);
      return Promise.resolve();
    } catch (e) {
      queryClient.invalidateQueries(QueryNames.CheckpointData);
      return Promise.reject();
    }
  };
  return {
    goToNextStep,
  };
};
